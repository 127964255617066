$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;
$colordark: #02111B;
$coloryellow: #FFD15C;
$colorpink: #426B69;
$colorblue: #426B69;
$colordefault: #454360;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}